import api from '../axios/api';

export const getAllCustomers = async () => {
  const { data } = await api.get('customers');
  return data;
};

export const createCustomer = async (user) => {
  const { data } = await api.post('customers', user);
  return data;
};

export const getSingleCustomer = async ({ queryKey }) => {
  const [_key, { id }] = queryKey;
  const { data } = await api.get(`customers/${id}`);
  return data;
};

// id already used getSingleCustomer so added duplicate.
export const getSingleCustomerDuplicate = async ({ queryKey }) => {
  const [_key, { customerId }] = queryKey;
  const { data } = await api.get(`customers/${customerId}`);
  return data;
};

export const updateCustomer = async ({ id, customer }) => {
  const { data } = await api.put(`customers/${id}`, customer);
  return data;
};

export const deleteCustomer = async ({ userId, user }) => {
  const { data } = await api.post(`customers/${userId}/deleteCustomer`, user);
  return data;
};

export const getJewelleryBoughtCustomer = async () => {
  const { data } = await api.get('customers/billing/getJewelleryBoughtCustomer');
  return data;
};

export const getSilverBoughtCustomer = async () => {
  const { data } = await api.get('customers/billing/getSilverBoughtCustomer');
  return data;
};

export const getWatchBoughtCustomer = async () => {
  const { data } = await api.get('customers/billing/getWatchBoughtCustomer');
  return data;
};

export const getBarBoughtCustomer = async () => {
  const { data } = await api.get('customers/billing/getBarBoughtCustomer');
  return data;
};

export const getJewelleryOrderBoughtCustomer = async () => {
  const { data } = await api.get('customers/billing/getJewelleryOrderBoughtCustomer');
  return data;
};

export const getCustomerPurchaseHistory = async ({ queryKey }) => {
  const [_key, { id }] = queryKey;
  const { data } = await api.get(`customers/history/${id}`);
  return data;
};

export const getCustomerLoyaltyPoints = async ({ queryKey }) => {
  const [_key, { id }] = queryKey;
  const { data } = await api.get(`customers/points/${id}`);
  return data;
};

export const deleteMultipleCustomers = async ({ ids, user }) => {
  const userDetails = { ids, user };
  const { data } = await api.post(`customers/deleteMany`, userDetails);
  return data;
};
