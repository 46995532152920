import { Container } from '@mui/material';
import PropTypes from 'prop-types';

const MainContainer = ({ children, ...rest }) => <Container {...rest}>{children}</Container>;

export default MainContainer;

MainContainer.propTypes = {
  children: PropTypes.any,
  rest: PropTypes.any,
};
