import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Link, useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getSinglePreOrder } from '../../api/preOrderApi';
import { getSingleCustomerDuplicate } from '../../api/customerApi';
import Loader from '../../components/loader/Loader';
import MainContainer from '../../components/mainContainer';
import BreadcrumbComponent from '../../components/breadcrumb/BreadcrumbComponent';
import ErrorComponent from '../../components/error/ErrorComponent';
import './Receipt.css';

const breadcrumbs = [
  <Link key="1" color="inherit" to={'/dashboard'}>
    Dashboard
  </Link>,
  <Link key="2" color="inherit" to={'..'}>
    PreOrder
  </Link>,
  <Typography key="3" color="text.primary">
    Receipt Print
  </Typography>,
];

const PreOrderReceiptPage = () => {
  const { id } = useParams();
  const {
    data,
    loading: getPreOrderLoading,
    error: getPreOrderError,
    isError: getPreOrderIsError,
  } = useQuery(['preOrder', { id }], getSinglePreOrder);

  const customerId = data?.customerId;

  const { data: customerData, loading: getCustomerLoading } = useQuery(
    ['customer', { customerId }],
    getSingleCustomerDuplicate,
    {
      enabled: !!customerId, // Only run this query if customerId is available
    }
  );

  const componentRef = useRef();
  const printPdf = useReactToPrint({
    content: () => componentRef.current,
  });

  if (getPreOrderLoading) {
    return <Loader />;
  }
  if (getCustomerLoading) {
    return <Loader />;
  }
  if (getPreOrderIsError) {
    return <ErrorComponent error={getPreOrderError} />;
  }
  return (
    <MainContainer>
      <BreadcrumbComponent breadcrumbs={breadcrumbs} />
      <div style={{ textAlign: 'center', marginBottom: '12px' }}>
        <Button onClick={printPdf} variant="contained" color="primary">
          Print
        </Button>
      </div>
      <div className="receipt-container" ref={componentRef}>
        <div className="receipt-inner">
          <div className="receipt-header">
            <h4>GURKHA WATCH AND ACCESSORIES</h4>
            <h4>NEWROAD-22, KATHMANDU NEPAL</h4>
            <h5>PRE-ODER MONEY RECEIPT</h5>
          </div>
          <div className="receipt-body">
            <div className="receipt-body-left">
              <p>ID {data?._id?.slice(-5)}</p>
              <p>CUSTOMER NAME:</p>
              <p>ADDRESS:</p>
              <p>NUMBER:</p>
              <p>WATCH MODEL:</p>
              <p>WATCH PRICE:</p>
              <p>DISCOUNT AMT:</p>
              <p>TOTAL AMT:</p>
              <p>ADVANCE AMT:</p>
              <p>DUE AMT:</p>
              <p>DELIVERY TIME:</p>
            </div>
            <div className="receipt-body-right">
              <p>DATE:{data?.orderDate}</p>
              <p>{data?.customerName}</p>
              <p>{customerData?.address}</p>
              <p>{customerData?.phone}</p>
              <p>{data?.name}</p>
              <p> Rs {data?.price} </p>
              <p> Rs {data?.discountAmount} </p>
              <p> Rs{data?.price * data?.quantity} </p>
              <p> Rs {data?.advance} </p>
              <p> Rs {data?.dueAmount} </p>
              <p> {data?.deadline}</p>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default PreOrderReceiptPage;
