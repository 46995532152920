import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Collapse, List, ListItemText } from '@mui/material';
//
import { AuthContext } from '../../context/AuthContext';
import Iconify from '../iconify/Iconify';
import SvgColor from '../svg-color/SvgColor';
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ ...other }) {
  const [openInventory, setOpenInventory] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const { authData } = useContext(AuthContext);
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        <StyledNavItem
          component={RouterLink}
          to="/dashboard/app"
          sx={{
            '&.active': {
              color: 'text.primary',
              bgcolor: 'action.selected',
              fontWeight: 'fontWeightBold',
            },
          }}
        >
          <StyledNavItemIcon>
            <SvgColor src={`/assets/icons/navbar/ic_analytics.svg`} sx={{ width: 1, height: 1 }} />
          </StyledNavItemIcon>

          <ListItemText disableTypography primary="dashboard" />
        </StyledNavItem>
      </List>
      {/* -------inventory now watch---------------------------------------------------------------------------------------------------------------------------------------- */}
      <List disablePadding sx={{ p: 1 }}>
        <StyledNavItem onClick={() => setOpenInventory((prev) => !prev)}>
          <StyledNavItemIcon>
            <Iconify icon="bi:watch" />
          </StyledNavItemIcon>

          <ListItemText disableTypography primary="watch" />
          {openInventory ? <Iconify icon="ic:baseline-expand-less" /> : <Iconify icon="ic:baseline-expand-more" />}
        </StyledNavItem>
      </List>
      {/* inventory sub lists starts now called watch -------------------------------------------------------------------- */}
      <Collapse in={openInventory} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/inventory/watch"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="inventory" />
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/inventory/pre-order"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="pre order" />
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/inventory/watchbilling/billing"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="billing" />
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/inventory/dispatch"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="watch dispatch" />
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/inventory/repair"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="repair" />
          </StyledNavItem>
        </List>
      </Collapse>
      {/* inventory now watch sub lists ends-------------------------------------------------------------------------------------------------------------- */}
      {/* -------order now jewellery--------------------------------------------------------------------------------------------------------------------------------------------- */}
      <List disablePadding sx={{ p: 1 }}>
        <StyledNavItem onClick={() => setOpenOrder((prev) => !prev)}>
          <StyledNavItemIcon>
            <Iconify icon="game-icons:necklace-display" />
          </StyledNavItemIcon>

          <ListItemText disableTypography primary="Jewellery" />
          {openOrder ? <Iconify icon="ic:baseline-expand-less" /> : <Iconify icon="ic:baseline-expand-more" />}
        </StyledNavItem>
      </List>
      {/* order sub lists starts ---------------------------------------------------------------------------------------------------------------------------------------- */}
      <Collapse in={openOrder} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/inventory/jewellery"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="jewellery" />
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/order/jewellery"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="jewellery order" />
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/order/bar"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="bar order" />
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/order/barBilling"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="bar billing" />
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/order/process"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="process" />
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/order/billing"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="billing" />
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/inventory/silver"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="silver" />
          </StyledNavItem>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/order/dispatch"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="dispatch" />
          </StyledNavItem>
        </List>
      </Collapse>
      {/* order sub lists ends---------------------------------------------------------------------------------------------------------------------------------------- */}
      {/* -------users---------------------------------------------------------------------------------------------------------------------------------------------*/}
      {authData?.role !== 'user' && (
        <List disablePadding sx={{ p: 1 }}>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/users"
            sx={{
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
              },
            }}
          >
            <StyledNavItemIcon>
              <SvgColor src={`/assets/icons/navbar/ic_user.svg`} sx={{ width: 1, height: 1 }} />
            </StyledNavItemIcon>

            <ListItemText disableTypography primary="users" />
          </StyledNavItem>
        </List>
      )}

      {/* -------factory--------------------------------------------------------------------------------------------------------------------------------------------- */}
      <List disablePadding sx={{ p: 1 }}>
        <StyledNavItem
          component={RouterLink}
          to="/dashboard/factory"
          sx={{
            '&.active': {
              color: 'text.primary',
              bgcolor: 'action.selected',
              fontWeight: 'fontWeightBold',
            },
          }}
        >
          <StyledNavItemIcon>
            <Iconify icon="material-symbols:factory" />
          </StyledNavItemIcon>

          <ListItemText disableTypography primary="factory" />
        </StyledNavItem>
      </List>
      {/* -------customers--------------------------------------------------------------------------------------------------------------------------------------------- */}
      <List disablePadding sx={{ p: 1 }}>
        <StyledNavItem
          component={RouterLink}
          to="/dashboard/customers"
          sx={{
            '&.active': {
              color: 'text.primary',
              bgcolor: 'action.selected',
              fontWeight: 'fontWeightBold',
            },
          }}
        >
          <StyledNavItemIcon>
            <SvgColor src={`/assets/icons/navbar/ic_user.svg`} sx={{ width: 1, height: 1 }} />
          </StyledNavItemIcon>

          <ListItemText disableTypography primary="customers" />
        </StyledNavItem>
      </List>
      {/* -------report--------------------------------------------------------------------------------------------------------------------------------------------- */}
      <List disablePadding sx={{ p: 1 }}>
        <StyledNavItem
          component={RouterLink}
          to="/dashboard/report"
          sx={{
            '&.active': {
              color: 'text.primary',
              bgcolor: 'action.selected',
              fontWeight: 'fontWeightBold',
            },
          }}
        >
          <StyledNavItemIcon>
            <Iconify icon="fluent-mdl2:report-add" />
          </StyledNavItemIcon>

          <ListItemText disableTypography primary="reports" />
        </StyledNavItem>
      </List>
      {/* -------trash--------------------------------------------------------------------------------------------------------------------------------------------- */}
      <List disablePadding sx={{ p: 1 }}>
        <StyledNavItem
          component={RouterLink}
          to="/dashboard/trash"
          sx={{
            '&.active': {
              color: 'text.primary',
              bgcolor: 'action.selected',
              fontWeight: 'fontWeightBold',
            },
          }}
        >
          <StyledNavItemIcon>
            <Iconify icon="ph:trash-fill" />
          </StyledNavItemIcon>

          <ListItemText disableTypography primary="trash" />
        </StyledNavItem>
      </List>

      {/* settings starts ----------------------------------------------------------------------------------------------------------------------------------------*/}
      {authData?.role !== 'user' && (
        <List disablePadding sx={{ p: 1 }}>
          <StyledNavItem onClick={() => setOpenSettings((prev) => !prev)}>
            <StyledNavItemIcon>
              {/* <SvgColor src={`/assets/icons/ic_notification_shipping.svg`} sx={{ width: 1, height: 1 }} /> */}
              <Iconify icon="solar:settings-bold-duotone" sx={{ width: 1, height: 1 }} />
            </StyledNavItemIcon>

            <ListItemText disableTypography primary="Settings" />
            {openSettings ? <Iconify icon="ic:baseline-expand-less" /> : <Iconify icon="ic:baseline-expand-more" />}
          </StyledNavItem>
        </List>
      )}
      {/* setting sub lists starts */}
      <Collapse in={openSettings} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/settings/email"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="email" />
          </StyledNavItem>
        </List>
        <List component="div" disablePadding>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/settings/socialMedia"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="SocialMedia" />
          </StyledNavItem>
        </List>
        <List component="div" disablePadding>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/settings/paymentType"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="Payment Type" />
          </StyledNavItem>
        </List>
        <List component="div" disablePadding>
          <StyledNavItem
            component={RouterLink}
            to="/dashboard/settings/issue"
            sx={{
              paddingLeft: '22px',
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
                paddingLeft: '22px',
              },
            }}
          >
            <StyledNavItemIcon>
              <Iconify icon="ph:dot-outline-bold" />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary="Issue" />
          </StyledNavItem>
        </List>
        {/* settings sub lists ends */}
      </Collapse>
    </Box>
  );
}

// ----------------------------------------------------------------------
