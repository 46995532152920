import api from '../axios/api';

export const getAllPreOrder = async () => {
  const { data } = await api.get('preOrder');
  return data;
};

export const createPreOrder = async ({ customer, orders }) => {
  const { data } = await api.post('preOrder', { customer, orders });
  return data;
};

export const getSinglePreOrder = async ({ queryKey }) => {
  const [_key, { id }] = queryKey;
  const { data } = await api.get(`preOrder/${id}`);
  return data;
};

export const updatePreOrder = async ({ id, preOrder }) => {
  const { data } = await api.put(`preOrder/${id}`, preOrder);
  return data;
};

export const cancelPreOrder = async (id) => {
  const { data } = await api.post(`preOrder/${id.id}/cancel`, id.user);
  return data;
};

export const deletePreOrder = async ({ userId, user }) => {
  const { data } = await api.post(`preOrder/${userId}/deleteOrder`, user);
  return data;
};
