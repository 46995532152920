import { Breadcrumbs } from '@mui/material';
import PropTypes from 'prop-types';

const BreadcrumbComponent = ({ breadcrumbs }) => (
  <Breadcrumbs separator="›" mb={3} aria-label="breadcrumb">
    {breadcrumbs}
  </Breadcrumbs>
);

BreadcrumbComponent.propTypes = {
  breadcrumbs: PropTypes.array,
};
export default BreadcrumbComponent;
